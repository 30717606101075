<template>
  <div class="cntCatalogo" v-if="!loading">
    <v-card
      class="cntQuadro zoom animate__animated animate__fadeInLeft"
      :ref="item.id"
      tile
      elevation="2"
      v-for="item in catalogoQuadri"
      :key="item.id"
      @click="goToDetail(item)"
      @mouseenter="isPhone ? null : openInfo(item)"
      @mouseleave="isPhone ? null : closeInfo(item)"
    >
      <div class="cntImg">
        <img width="100%" :src="item.foto1" />
      </div>
      <v-card-subtitle style="display: block" :ref="'subtitle1_' + item._id" v-if="!isPhone">
        <div class="font-weight-black" v-html="item.nome"></div>
      </v-card-subtitle>
      <v-card-subtitle class="subtitle2" :style="isPhone ? 'display: block' : 'display: none'" :ref="'subtitle2_' + item._id">
        <div class="tooltip">
          <div class="cntNomeEDim">
            <p v-html="item.nome"></p>
            <p>{{ item.dimensioni }}</p>
          </div>
          <p class="descrizione" v-html="item.descrizione"></p>
        </div>
      </v-card-subtitle>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["isPhone"],
  data() {
    return {
      showDescriptions: {},
      componentRender: 0,
      addSucces: false
    };
  },

  computed: {
    ...mapGetters({
      catalogoQuadri: "getCatalogo",
      loading: "getLoading"
    })
  },

  async created() {
    await this.getCatalogoAsync();
  },

  mounted() {
    this.catalogoQuadri;
  },

  methods: {
    ...mapActions(["getCatalogoAsync"]),
    openInfo(item) {
      this.$refs["subtitle1_" + item._id][0].style.display = "none";
      this.$refs["subtitle2_" + item._id][0].style.display = "block";
    },

    closeInfo(item) {
      this.$refs["subtitle1_" + item._id][0].style.display = "block";
      this.$refs["subtitle2_" + item._id][0].style.display = "none";
    },

    goToDetail(item) {
      this.$router.push({ name: "detail", params: { id: item._id, isPhone: this.isPhone } });
    }
  }
};
</script>

<style lang="scss">
.cntCatalogo {
  margin: 100px 16px 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  .cntQuadro {
    margin: 30px;
    width: 17rem;
    cursor: pointer;
    background-color: transparent !important;
    box-shadow: unset !important;
    .cntImg {
      display: flex;
      align-items: end;
      height: 25rem;
    }
    .v-card__subtitle {
      display: flex;
      justify-content: space-between;
      padding: 3px 7px;
      color: white !important;
    }
    .subtitle2 {
      margin-top: 10px;
      padding: unset;
      margin-top: unset;
      line-height: unset;
    }
    .tooltip {
      color: white;
      padding: 10px;
      //border-radius: 0 0 10px 10px;
      background-color: rgba(109, 131, 177, 0.349);
      .cntNomeEDim {
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        font-size: 14px;
      }
      .descrizione {
        margin-top: 10xp;
        font-size: 12px;
        height: 50px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        white-space: pre-wrap;
      }
    }
  }
}
.phone {
  .cntCatalogo {
    margin: 70px 16px 40px;
    display: block;
    .cntQuadro {
      margin: 20px 0;
      width: 100%;
      .cntImg {
        height: unset;
      }
    }
  }
}

.zoom:hover {
  transform: scale(1.2);
  transition: all ease 500ms;
}
</style>
